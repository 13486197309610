import { template as template_427371dd971d4fc7913a2544c8597fd9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_427371dd971d4fc7913a2544c8597fd9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
