import { template as template_396b9ef54c824877adb9de89a7910646 } from "@ember/template-compiler";
const SidebarSectionMessage = template_396b9ef54c824877adb9de89a7910646(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
