import { template as template_e279a45c950b4ed29c30d3ea53c5bc17 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e279a45c950b4ed29c30d3ea53c5bc17(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
