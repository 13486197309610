import { template as template_dee9640d887b4fb2bcd9efacc615a589 } from "@ember/template-compiler";
const FKText = template_dee9640d887b4fb2bcd9efacc615a589(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
